export const GET_COST_CENTER_SUCCESS = "GET_COST_CENTER_SUCCSS";
export const GET_COST_CENTER_ERROR = "GET_COST_CENTER_ERROR";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";
export const GET_CURRENT_DEBT_LİST_SUCCESS = "GET_CURRENT_DEBT_LİST_SUCCESS";
export const GET_CURRENT_DEBT_LİST_ERROR = "GET_CURRENT_DEBT_LİST_ERROR";
export const GET_MY_USER_DETAIL_SUCCESS = "GET_MY_USER_DETAIL_SUCCESS";
export const GET_MY_USER_DETAIL_ERROR = "GET_MY_USER_DETAIL_ERROR";
export const GET_MAİN_COST_CENTER_GROUP_SUCCESS =
  "GET_MAİN_COST_CENTER_GROUP__SUCCESS";
export const GET_MAİN_COST_CENTER_GROUP_ERROR =
  "GET_MAİN_COST_CENTER_GROUP__ERROR";
export const GET_SUB_COST_CENTER_GROUP_SUCCESS =
  "GET_SUB_COST_CENTER_GROUP_SUCCESS";
export const GET_SUB_COST_CENTER_GROUP_ERROR =
  "GET_SUB_COST_CENTER_GROUP_ERROR";
export const GET_COST_CENTER_LİST_SUCCESS = "GET_COST_CENTER_LİST_SUCCESS";
export const GET_COST_CENTER_LİST_ERROR = "GET_COST_CENTER_LİST_ERROR";
export const GET_TEKNOPARK_LİST_SUCCESS = "GET_TEKNOPARK_LİST_SUCCESS";
export const GET_TEKNOPARK_LİST_ERROR = "GET_TEKNOPARK_LİST_ERROR";
export const UPDATE_TEKNOPARK_LİST_SUCCESS = "UPDATE_TEKNOPARK_LİST_SUCCESS";
export const UPDATE_TEKNOPARK_LİST_ERROR = "UPDATE_TEKNOPARK_LİST_ERROR";
export const GET_COST_CENTER_DETAIL_ERROR = "GET_COST_CENTER_DETAIL_ERROR";
export const GET_COST_CENTER_DETAIL_SUCCESS = "GET_COST_CENTER_DETAIL_SUCCESS";
export const GET_FİRM_NAMES_SUCCESS = "GET_FİRM_NAMES_SUCCESS";
export const GET_FİRM_NAMES_ERROR = "GET_FİRM_NAMES_ERROR";
export const GET_STARTİNG_WORK_EMPLOYEES_SUCCESS =
  "GET_STARTİNG_WORK_EMPLOYEES_SUCCESS";
export const GET_STARTİNG_WORK_EMPLOYEES_ERROR =
  "GET_STARTİNG_WORK_EMPLOYEES_ERROR";
export const GET_TECHNOPARK_NAME_LİST_SUCCESS =
  "GET_TECHNOPARK_NAME_LİST_SUCCESS";
export const GET_TECHNOPARK_NAME_LİST_ERROR = "GET_TECHNOPARK_NAME_LİST_ERROR";
export const GET_RELATED_MEMBER__NAME_LİST_SUCCESS =
  "GET_RELATED_MEMBER__NAME_LİST_SUCCESS";
export const GET_RELATED_MEMBER__NAME_LİST_ERROR =
  "GET_RELATED_MEMBER__NAME_LİST_ERROR ";
export const GET_PERSONAL_TYPE_LİST_SUCCESS = "GET_PERSONAL_TYPE_LİST_SUCCESS";
export const GET_PERSONAL_TYPE_LİST_ERROR = "GET_PERSONAL_TYPE_LİST_ERROR";
export const GET_PROJECT_NAME_LİST_SUCCESS = "GET_PROJECT_NAME_LİST_SUCCESS";
export const GET_PROJECT_NAME_LİST_ERROR = "GET_PROJECT_NAME_LİST_ERROR";
export const GET_PROJECT_STATUS_LİST_SUCCESS =
  "GET_PROJECT_STATUS_LİST_SUCCESS";
export const GET_PROJECT_STATUS_LİST_ERROR = "GET_PROJECT_STATUS_LİST_ERROR";
export const GET_PROJECT_ASSİGNMENT_LİST_SUCCESS =
  "GET_PROJECT_ASSİGNMENT_LİST_SUCCESS";
export const GET_PROJECT_ASSİGNMENT_LİST_ERROR =
  "GET_PROJECT_ASSİGNMENT_LİST_ERROR";
export const GET_ALL_CUSTOMER_SUCCESS = "GET_ALL_CUSTOMER_SUCCESS";
export const GET_ALL_CUSTOMER_ERROR = "GET_ALL_CUSTOMER_ERROR";
export const GET_ALL_PASSIVE_CUSTOMER_SUCCESS =
  "GET_ALL_PASSIVE_CUSTOMER_SUCCESS";
export const GET_ALL_PASSIVE_CUSTOMER_ERROR = "GET_ALL_PASSIVE_CUSTOMER_ERROR";
export const GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_SUCCESS =
  "GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_SUCCESS";
export const GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_ERROR =
  "GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_ERROR";
export const GET_LEAVİNG_PERSONAL_LİST_SUCCESS =
  "GET_LEAVİNG_PERSONAL_LİST_SUCCESS";
export const GET_LEAVİNG_PERSONAL_LİST_ERROR =
  "GET_LEAVİNG_PERSONAL_LİST_ERROR";
export const GET_ALL_TECHNOPARK_NAME_LİST_SUCCESS =
  "GET_ALL_TECHNOPARK_NAME_LİST_SUCCESS";
export const GET_ALL_TECHNOPARK_NAME_LİST_ERROR =
  "GET_ALL_TECHNOPARK_NAME_LİST_ERROR";

export interface Member {
  id: number;
  userName: string;
}
export interface UserDetail {
  id: number;
  userName: string;
}
export interface CostCenter {
  id: number;
}
export interface Companies {
  id: number;
}

export interface CustomerList {
  id: number;
}
export interface PassiveCustomerList {
  id: number;
}
export interface PersonnelNameForProjectAssignmentList {}
export interface CurrentDebt {
  firmName: string;
  input: string;
  debtDate: Date;
  costCenterCode: number;
  companies: number;
}
export interface MainCostCenter {
  name: string;
}
export interface SubCostCenter {
  name: string;
}
export interface CostCenterList {
  companies: number;
  mainGroupName: string;
}
export interface TeknoparkList {}
export interface TeknoparkUpdateList {}
export interface CostCenterDetail {
  name: string;
}
export interface Firm {
  firmName: string;
}
export interface StartingWorkEmployees {}
export interface TechnoparkNameList {}
export interface ProjectNameList {}

export interface RelatedMemberNameList {}
export interface PersonalTypeList {}

export interface ProjectAssignmentList {}

export interface LeavingPersonalList {}
export interface AllTechnoparkNameList {}

interface getCustomerListSuccess {
  type: typeof GET_ALL_CUSTOMER_SUCCESS;
  payload: CustomerList[];
}

interface getCustomerListError {
  type: typeof GET_ALL_CUSTOMER_ERROR;
  payload: string;
}
interface getPassiveCustomerListSuccess {
  type: typeof GET_ALL_PASSIVE_CUSTOMER_SUCCESS;
  payload: PassiveCustomerList[];
}

interface getPassiveCustomerListError {
  type: typeof GET_ALL_PASSIVE_CUSTOMER_ERROR;
  payload: string;
}
interface getPersonnelNameForProjectAssignmentSuccess {
  type: typeof GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_SUCCESS;
  payload: PersonnelNameForProjectAssignmentList[];
}

interface getPersonnelNameForProjectAssignmentError {
  type: typeof GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_ERROR;
  payload: string;
}
interface getComponiesSuccess {
  type: typeof GET_COMPANIES_SUCCESS;
  payload: Companies[];
}

interface getComponiesError {
  type: typeof GET_COMPANIES_ERROR;
  payload: string;
}

interface getCostCenterListSuccess {
  type: typeof GET_COST_CENTER_SUCCESS;
  payload: CostCenter[];
}

interface getCostCenterListError {
  type: typeof GET_COST_CENTER_ERROR;
  payload: string;
}

interface getCurrentDebtListSuccess {
  type: typeof GET_CURRENT_DEBT_LİST_SUCCESS;
  payload: CurrentDebt[];
}
interface getCurrentDebtListError {
  type: typeof GET_CURRENT_DEBT_LİST_ERROR;
  payload: string;
}
interface getMyUserDetailListSuccess {
  type: typeof GET_MY_USER_DETAIL_SUCCESS;
  payload: UserDetail[];
}

interface getMyUserDetailListError {
  type: typeof GET_MY_USER_DETAIL_ERROR;
  payload: string;
}
interface getMainCostCenterGroupSuccess {
  type: typeof GET_MAİN_COST_CENTER_GROUP_SUCCESS;
  payload: MainCostCenter[];
}

interface getMainCostCenterGroupError {
  type: typeof GET_MAİN_COST_CENTER_GROUP_ERROR;
  payload: string;
}
interface getSubCostCenterGroupSuccess {
  type: typeof GET_SUB_COST_CENTER_GROUP_SUCCESS;
  payload: SubCostCenter[];
}

interface getSubCostCenterGroupError {
  type: typeof GET_SUB_COST_CENTER_GROUP_ERROR;
  payload: string;
}
interface getAllCostCenterListSuccess {
  type: typeof GET_COST_CENTER_LİST_SUCCESS;
  payload: CostCenterList[];
}

interface getAllCostCenterListError {
  type: typeof GET_COST_CENTER_LİST_ERROR;
  payload: string;
}

interface updateTeknoparkListSuccess {
  type: typeof UPDATE_TEKNOPARK_LİST_SUCCESS;
  payload: TeknoparkUpdateList[];
}

interface updateTeknoparkListError {
  type: typeof UPDATE_TEKNOPARK_LİST_ERROR;
  payload: string;
}

interface getAllTeknoparkListSuccess {
  type: typeof GET_TEKNOPARK_LİST_SUCCESS;
  payload: TeknoparkList[];
}

interface getAllTeknoparkListError {
  type: typeof GET_TEKNOPARK_LİST_ERROR;
  payload: string;
}
interface getCostCenterDetailSuccess {
  type: typeof GET_COST_CENTER_DETAIL_SUCCESS;
  payload: CostCenterDetail[];
}

interface getCostCenterDetailError {
  type: typeof GET_COST_CENTER_DETAIL_ERROR;
  payload: string;
}
interface getFirmNamesSuccess {
  type: typeof GET_FİRM_NAMES_SUCCESS;
  payload: Firm[];
}

interface getFirmNamesError {
  type: typeof GET_FİRM_NAMES_ERROR;
  payload: string;
}
interface getStartingWorkEmployeesSuccess {
  type: typeof GET_STARTİNG_WORK_EMPLOYEES_SUCCESS;
  payload: StartingWorkEmployees[];
}

interface getStartingWorkEmployeesError {
  type: typeof GET_STARTİNG_WORK_EMPLOYEES_ERROR;
  payload: string;
}
interface getTechnoparkNameListSuccess {
  type: typeof GET_TECHNOPARK_NAME_LİST_SUCCESS;
  payload: TechnoparkNameList[];
}

interface getTechnoparkNameListError {
  type: typeof GET_TECHNOPARK_NAME_LİST_ERROR;
  payload: string;
}

interface getRelatedMemberNameListSuccess {
  type: typeof GET_RELATED_MEMBER__NAME_LİST_SUCCESS;
  payload: StartingWorkEmployees[];
}

interface getRelatedMemberNameListError {
  type: typeof GET_RELATED_MEMBER__NAME_LİST_ERROR;
  payload: string;
}

interface getPersonalTypeListSuccess {
  type: typeof GET_PERSONAL_TYPE_LİST_SUCCESS;
  payload: StartingWorkEmployees[];
}

interface getPersonalTypeListError {
  type: typeof GET_PERSONAL_TYPE_LİST_ERROR;
  payload: string;
}

interface getProjectNameListSuccess {
  type: typeof GET_PROJECT_NAME_LİST_SUCCESS;
  payload: StartingWorkEmployees[];
}

interface getProjectNameListError {
  type: typeof GET_PROJECT_NAME_LİST_ERROR;
  payload: string;
}

interface getProjectAssignmentListSuccess {
  type: typeof GET_PROJECT_ASSİGNMENT_LİST_SUCCESS;
  payload: ProjectAssignmentList[];
}

interface getProjectAssignmentListError {
  type: typeof GET_PROJECT_ASSİGNMENT_LİST_ERROR;
  payload: string;
}

interface getLeavingPersonalListSuccess {
  type: typeof GET_LEAVİNG_PERSONAL_LİST_SUCCESS;
  payload: LeavingPersonalList[];
}

interface getLeavingPersonalListError {
  type: typeof GET_LEAVİNG_PERSONAL_LİST_ERROR;
  payload: string;
}

interface getAllTechnoparkNameListSuccess {
  type: typeof GET_ALL_TECHNOPARK_NAME_LİST_SUCCESS;
  payload: AllTechnoparkNameList[];
}

interface getAllTechnoparkNameListError {
  type: typeof GET_ALL_TECHNOPARK_NAME_LİST_ERROR;
  payload: string;
}

export type memberActions =
  | getCostCenterListSuccess
  | getCustomerListSuccess
  | getCustomerListError
  | getCostCenterListError
  | getComponiesSuccess
  | getComponiesError
  | getCurrentDebtListSuccess
  | getCurrentDebtListError
  | getMyUserDetailListSuccess
  | getMyUserDetailListError
  | getMainCostCenterGroupSuccess
  | getMainCostCenterGroupError
  | getSubCostCenterGroupSuccess
  | getSubCostCenterGroupError
  | getAllCostCenterListSuccess
  | getAllCostCenterListError
  | getAllTeknoparkListError
  | getAllTeknoparkListSuccess
  | updateTeknoparkListSuccess
  | updateTeknoparkListError
  | getCostCenterDetailSuccess
  | getCostCenterDetailError
  | getFirmNamesError
  | getFirmNamesSuccess
  | getStartingWorkEmployeesSuccess
  | getStartingWorkEmployeesError
  | getTechnoparkNameListSuccess
  | getTechnoparkNameListError
  | getRelatedMemberNameListSuccess
  | getRelatedMemberNameListError
  | getPersonalTypeListSuccess
  | getPersonalTypeListError
  | getPersonalTypeListSuccess
  | getProjectNameListError
  | getProjectNameListSuccess
  | getProjectAssignmentListSuccess
  | getProjectAssignmentListError
  | getPassiveCustomerListSuccess
  | getPassiveCustomerListError
  | getPersonnelNameForProjectAssignmentSuccess
  | getPersonnelNameForProjectAssignmentError
  | getLeavingPersonalListSuccess
  | getLeavingPersonalListError
  | getAllTechnoparkNameListSuccess
  | getAllTechnoparkNameListError;
