import {
  UserDetail,
  memberActions,
  CostCenter,
  GET_COST_CENTER_SUCCESS,
  GET_COST_CENTER_ERROR,
  Companies,
  GET_COMPANIES_SUCCESS,
  CustomerList,
  PassiveCustomerList,
  GET_COMPANIES_ERROR,
  GET_CURRENT_DEBT_LİST_SUCCESS,
  GET_CURRENT_DEBT_LİST_ERROR,
  CurrentDebt,
  GET_MY_USER_DETAIL_SUCCESS,
  GET_MY_USER_DETAIL_ERROR,
  MainCostCenter,
  GET_MAİN_COST_CENTER_GROUP_SUCCESS,
  GET_MAİN_COST_CENTER_GROUP_ERROR,
  SubCostCenter,
  GET_SUB_COST_CENTER_GROUP_ERROR,
  GET_SUB_COST_CENTER_GROUP_SUCCESS,
  CostCenterList,
  GET_COST_CENTER_LİST_ERROR,
  GET_COST_CENTER_LİST_SUCCESS,
  GET_COST_CENTER_DETAIL_SUCCESS,
  GET_COST_CENTER_DETAIL_ERROR,
  CostCenterDetail,
  Firm,
  GET_FİRM_NAMES_ERROR,
  GET_FİRM_NAMES_SUCCESS,
  GET_STARTİNG_WORK_EMPLOYEES_ERROR,
  GET_STARTİNG_WORK_EMPLOYEES_SUCCESS,
  StartingWorkEmployees,
  GET_TECHNOPARK_NAME_LİST_ERROR,
  GET_TECHNOPARK_NAME_LİST_SUCCESS,
  GET_PROJECT_NAME_LİST_SUCCESS,
  GET_PROJECT_NAME_LİST_ERROR,
  GET_PERSONAL_TYPE_LİST_SUCCESS,
  GET_PERSONAL_TYPE_LİST_ERROR,
  GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_ERROR,
  GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_SUCCESS,
  PersonalTypeList,
  TechnoparkNameList,
  PersonnelNameForProjectAssignmentList,
  ProjectNameList,
  UPDATE_TEKNOPARK_LİST_SUCCESS,
  GET_TEKNOPARK_LİST_ERROR,
  GET_TEKNOPARK_LİST_SUCCESS,
  TeknoparkList,
  ProjectAssignmentList,
  GET_PROJECT_ASSİGNMENT_LİST_SUCCESS,
  GET_PROJECT_ASSİGNMENT_LİST_ERROR,
  GET_ALL_CUSTOMER_SUCCESS,
  GET_ALL_CUSTOMER_ERROR,
  GET_ALL_PASSIVE_CUSTOMER_ERROR,
  GET_ALL_PASSIVE_CUSTOMER_SUCCESS,
  GET_LEAVİNG_PERSONAL_LİST_ERROR,
  GET_LEAVİNG_PERSONAL_LİST_SUCCESS,
  LeavingPersonalList,
  GET_RELATED_MEMBER__NAME_LİST_SUCCESS,
  GET_RELATED_MEMBER__NAME_LİST_ERROR,
  RelatedMemberNameList,
  TeknoparkUpdateList,
  UPDATE_TEKNOPARK_LİST_ERROR,
  GET_ALL_TECHNOPARK_NAME_LİST_SUCCESS,
  GET_ALL_TECHNOPARK_NAME_LİST_ERROR,
  AllTechnoparkNameList,
} from "../../redux/actions/actionTypes";

//state'mizi oluşturuyoruz. Dataları burada barındırıypruz. TaskState'tipinde olduğunu belittyoruz.

interface CostCenterState {
  costCenterList: CostCenter[];
  message: string;
}

const initialStateCostCenter: CostCenterState = {
  costCenterList: [],
  message: "",
};

interface CompaniesState {
  companiesList: Companies[];
  message: string;
}

interface CustomersState {
  customerList: CustomerList[];
  message: string;
}
interface PassiveCustomersState {
  passiveCustomerList: PassiveCustomerList[];
  message: string;
}

const initialStateCompanies: CompaniesState = {
  companiesList: [],
  message: "",
};
const initialStateCustomers: CustomersState = {
  customerList: [],
  message: "",
};
const initialStatePassiveCustomers: PassiveCustomersState = {
  passiveCustomerList: [],
  message: "",
};

interface CurrentDebtState {
  currentDebtList: CurrentDebt[];
  message: string;
}

const initialStateCurrentDebt: CurrentDebtState = {
  currentDebtList: [],
  message: "",
};

interface MyUserDetailListState {
  myUserDetailList: UserDetail[];
  message: string;
}

const initialStateMyUserDetail: MyUserDetailListState = {
  myUserDetailList: [],
  message: "",
};

interface MainCostCenterState {
  mainCostCenterList: MainCostCenter[];
  message: string;
}

const initialStateMainCostCenter: MainCostCenterState = {
  mainCostCenterList: [],
  message: "",
};

interface SubCostCenterState {
  subCostCenterList: SubCostCenter[];
  message: string;
}

const initialStateSubCostCenter: SubCostCenterState = {
  subCostCenterList: [],
  message: "",
};
interface CostCenterListState {
  costAllCenterList: CostCenterList[];
  message: string;
}

const initialStateCostCenterList: CostCenterListState = {
  costAllCenterList: [],
  message: "",
};

interface CostCenterDetailState {
  costCenterDetail: CostCenterDetail[];
  message: string;
}

const initialStateCostCenterDetail: CostCenterDetailState = {
  costCenterDetail: [],
  message: "",
};
interface FirmState {
  firm: Firm[];
  message: string;
}

const initialStateFirm: FirmState = {
  firm: [],
  message: "",
};

interface StartingWorkEmployeesState {
  startingWorkEmployees: StartingWorkEmployees[];
  message: string;
}

const initialStateStartingWorkEmployees: StartingWorkEmployeesState = {
  startingWorkEmployees: [],
  message: "",
};
interface TechnoparkNameListState {
  technoparkNameList: TechnoparkNameList[];
  message: string;
}

interface PersonnelNameForProjectAssignmentListState {
  personnelNameForProjectAssignmentList: PersonnelNameForProjectAssignmentList[];
  message: string;
}

const initialStateTechnoparkNameList: TechnoparkNameListState = {
  technoparkNameList: [],
  message: "",
};

const initialPersonnelNameForProjectAssignmentList: PersonnelNameForProjectAssignmentListState =
  {
    personnelNameForProjectAssignmentList: [],
    message: "",
  };

interface RelatedMemberNameListState {
  relatedMemberNameList: RelatedMemberNameList[];
  message: string;
}

const initialStateRelatedMemberNameList: RelatedMemberNameListState = {
  relatedMemberNameList: [],
  message: "",
};

interface PersonalTypeListState {
  personalTypeList: PersonalTypeList[];
  message: string;
}

const initialStatePersonalTypeList: PersonalTypeListState = {
  personalTypeList: [],
  message: "",
};
interface ProjectNameListState {
  projectNameList: ProjectNameList[];
  message: string;
}

const initialStateProjectNameList: ProjectNameListState = {
  projectNameList: [],
  message: "",
};

interface TeknoparkListState {
  teknoparkList: TeknoparkList[];
  message: string;
}

const initialStateTeknoparkList: TeknoparkListState = {
  teknoparkList: [],
  message: "",
};

interface TeknoparkUpdateState {
  teknoparkUpdateList: TeknoparkUpdateList[];
  message: string;
}

const initialStateTeknoparkUpdateList: TeknoparkUpdateState = {
  teknoparkUpdateList: [],
  message: "",
};

interface ProjectAssignmentListState {
  projectAssignmentList: ProjectAssignmentList[];
  message: string;
}

const initialStateProjectAssignmentList: ProjectAssignmentListState = {
  projectAssignmentList: [],
  message: "",
};

interface LeavingPersonalListState {
  leavingPersonalList: LeavingPersonalList[];
  message: string;
}

const initialStateLeavingPersonalList: LeavingPersonalListState = {
  leavingPersonalList: [],
  message: "",
};

interface AllTechnoparkNameListState {
  allTechnoparkNameList: AllTechnoparkNameList[];
  message: string;
}

const initialStateAllTechnoparkNameList: AllTechnoparkNameListState = {
  allTechnoparkNameList: [],
  message: "",
};

export function costCenterReducer(
  state: CostCenterState = initialStateCostCenter,
  action: memberActions
): CostCenterState {
  switch (action.type) {
    case GET_COST_CENTER_SUCCESS: {
      return {
        ...state,
        costCenterList: action.payload,
      };
    }
    case GET_COST_CENTER_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function companiesReducer(
  state: CompaniesState = initialStateCompanies,
  action: memberActions
): CompaniesState {
  switch (action.type) {
    case GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        companiesList: action.payload,
      };
    }
    case GET_COMPANIES_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function customersReducer(
  state: CustomersState = initialStateCustomers,
  action: memberActions
): CustomersState {
  switch (action.type) {
    case GET_ALL_CUSTOMER_SUCCESS: {
      return {
        ...state,
        customerList: action.payload,
      };
    }
    case GET_ALL_CUSTOMER_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function passiveCustomerReducer(
  state: PassiveCustomersState = initialStatePassiveCustomers,
  action: memberActions
): PassiveCustomersState {
  switch (action.type) {
    case GET_ALL_PASSIVE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        passiveCustomerList: action.payload,
      };
    }
    case GET_ALL_PASSIVE_CUSTOMER_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function currentDebtReducer(
  state: CurrentDebtState = initialStateCurrentDebt,
  action: memberActions
): CurrentDebtState {
  switch (action.type) {
    case GET_CURRENT_DEBT_LİST_SUCCESS: {
      return {
        ...state,
        currentDebtList: action.payload,
      };
    }
    case GET_CURRENT_DEBT_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function myUserDetailReducer(
  state: MyUserDetailListState = initialStateMyUserDetail,
  action: memberActions
): MyUserDetailListState {
  switch (action.type) {
    case GET_MY_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        myUserDetailList: action.payload,
      };
    }
    case GET_MY_USER_DETAIL_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function mainCostCenterReducer(
  state: MainCostCenterState = initialStateMainCostCenter,
  action: memberActions
): MainCostCenterState {
  switch (action.type) {
    case GET_MAİN_COST_CENTER_GROUP_SUCCESS: {
      return {
        ...state,
        mainCostCenterList: action.payload,
      };
    }
    case GET_MAİN_COST_CENTER_GROUP_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function subCostCenterReducer(
  state: SubCostCenterState = initialStateSubCostCenter,
  action: memberActions
): SubCostCenterState {
  switch (action.type) {
    case GET_SUB_COST_CENTER_GROUP_SUCCESS: {
      return {
        ...state,
        subCostCenterList: action.payload,
      };
    }
    case GET_SUB_COST_CENTER_GROUP_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function allCostCenterReducer(
  state: CostCenterListState = initialStateCostCenterList,
  action: memberActions
): CostCenterListState {
  switch (action.type) {
    case GET_COST_CENTER_LİST_SUCCESS: {
      return {
        ...state,
        costAllCenterList: action.payload,
      };
    }
    case GET_COST_CENTER_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function costCenterDetailReducer(
  state: CostCenterDetailState = initialStateCostCenterDetail,
  action: memberActions
): CostCenterDetailState {
  switch (action.type) {
    case GET_COST_CENTER_DETAIL_SUCCESS: {
      return {
        ...state,
        costCenterDetail: action.payload,
      };
    }
    case GET_COST_CENTER_DETAIL_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function firmReducer(
  state: FirmState = initialStateFirm,
  action: memberActions
): FirmState {
  switch (action.type) {
    case GET_FİRM_NAMES_SUCCESS: {
      return {
        ...state,
        firm: action.payload,
      };
    }
    case GET_FİRM_NAMES_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function startingWorkEmployeesReducer(
  state: StartingWorkEmployeesState = initialStateStartingWorkEmployees,
  action: memberActions
): StartingWorkEmployeesState {
  switch (action.type) {
    case GET_STARTİNG_WORK_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        startingWorkEmployees: action.payload,
      };
    }
    case GET_STARTİNG_WORK_EMPLOYEES_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function technoparkNameListReducer(
  state: TechnoparkNameListState = initialStateTechnoparkNameList,
  action: memberActions
): TechnoparkNameListState {
  switch (action.type) {
    case GET_TECHNOPARK_NAME_LİST_SUCCESS: {
      return {
        ...state,
        technoparkNameList: action.payload,
      };
    }
    case GET_TECHNOPARK_NAME_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function personnelNameForProjectAssignmentListReducer(
  state: PersonnelNameForProjectAssignmentListState = initialPersonnelNameForProjectAssignmentList,
  action: memberActions
): PersonnelNameForProjectAssignmentListState {
  switch (action.type) {
    case GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        personnelNameForProjectAssignmentList: action.payload,
      };
    }
    case GET_PERSONNEL_NAME_FOR_PROJECT_ASSIGNMENT_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function relatedMemberNameListReducer(
  state: RelatedMemberNameListState = initialStateRelatedMemberNameList,
  action: memberActions
): RelatedMemberNameListState {
  switch (action.type) {
    case GET_RELATED_MEMBER__NAME_LİST_SUCCESS: {
      return {
        ...state,
        relatedMemberNameList: action.payload,
      };
    }
    case GET_RELATED_MEMBER__NAME_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function personalTypeListReducer(
  state: PersonalTypeListState = initialStatePersonalTypeList,
  action: memberActions
): PersonalTypeListState {
  switch (action.type) {
    case GET_PERSONAL_TYPE_LİST_SUCCESS: {
      return {
        ...state,
        personalTypeList: action.payload,
      };
    }
    case GET_PERSONAL_TYPE_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function projectNameListReducer(
  state: ProjectNameListState = initialStateProjectNameList,
  action: memberActions
): ProjectNameListState {
  switch (action.type) {
    case GET_PROJECT_NAME_LİST_SUCCESS: {
      return {
        ...state,
        projectNameList: action.payload,
      };
    }
    case GET_PROJECT_NAME_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function teknoparkListReducer(
  state: TeknoparkListState = initialStateTeknoparkList,
  action: memberActions
): TeknoparkListState {
  switch (action.type) {
    case GET_TEKNOPARK_LİST_SUCCESS: {
      return {
        ...state,
        teknoparkList: action.payload,
      };
    }
    case GET_TEKNOPARK_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function teknoparkUpdateReducer(
  state: TeknoparkUpdateState = initialStateTeknoparkUpdateList,
  action: memberActions
): TeknoparkUpdateState {
  switch (action.type) {
    case UPDATE_TEKNOPARK_LİST_SUCCESS: {
      return {
        ...state,
        teknoparkUpdateList: action.payload,
      };
    }
    case UPDATE_TEKNOPARK_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function projectAssignmentListReducer(
  state: ProjectAssignmentListState = initialStateProjectAssignmentList,
  action: memberActions
): ProjectAssignmentListState {
  switch (action.type) {
    case GET_PROJECT_ASSİGNMENT_LİST_SUCCESS: {
      return {
        ...state,
        projectAssignmentList: action.payload,
      };
    }
    case GET_PROJECT_ASSİGNMENT_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function leavingPersonalListReducer(
  state: LeavingPersonalListState = initialStateLeavingPersonalList,
  action: memberActions
): LeavingPersonalListState {
  switch (action.type) {
    case GET_LEAVİNG_PERSONAL_LİST_SUCCESS: {
      return {
        ...state,
        leavingPersonalList: action.payload,
      };
    }
    case GET_LEAVİNG_PERSONAL_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function allTechnoparkNameListReducer(
  state: AllTechnoparkNameListState = initialStateAllTechnoparkNameList,
  action: memberActions
): AllTechnoparkNameListState {
  switch (action.type) {
    case GET_ALL_TECHNOPARK_NAME_LİST_SUCCESS: {
      return {
        ...state,
        allTechnoparkNameList: action.payload,
      };
    }
    case GET_ALL_TECHNOPARK_NAME_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
